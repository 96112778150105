import { Helmet, HelmetProvider } from 'react-helmet-async';
import React from 'react';

const keywordsData = {
    "home": ["dadina", "dadina αθηνα", "dadina beauty", "dadina beauty αθηνα", "dadina beauty world",
        "dadina beauty world αθηνα", "dadina world", "beauty world", "world of beauty", "dadina νυχια",
        "dadina nails", "δναιλσ", "dnails", "dnails νυχια", "d nails", "dnails αθηνα", "d nails αθηνα",
        "dadina nalis", "dadina νυχια", "dadina nyxia", "dadina nixia", "dadna nails", "ntantina", "ntantina αθηνα",
        "ntantina nails", "ntantina νυχια", "νταντινα", "νταντινα nails", "νταντινα νυχια", "νταντινα nixia",
        "tattoo φρυδια", "tattoo φρυδια αθηνα", "tattoo φρυδια κοντα μου", "tattoo frudia", "tattoo fridia",
        "eyebrow tattoo", "βλεφαριδεσ τριχα τριχα", "βλεφαριδεσ φυσικο αποτελεσμα", "πολυ κοντά νύχια", "νύχια",
        "nails", "μανικιουρ", "πεντικιουρ", "ημιμονιμο", "θεραπευτικο πεντικιουρ", "ακρυλικο", "συντηρηση ακρυλικο",
        "συντηρηση ακρυλικο gel", "συντηρηση ακρυλικο τζελ", "συντηρηση acrygel", "συντηρηση ακριτζελ",
        "συντηρηση ακρυτζελ", "συντηρηση gel", "συντηρηση τζελ", "τοποθετηση ακρυλικο", "τοποθετηση ακρυλικο gel",
        "τοποθετηση ακρυλικο τζελ", "τοποθετηση acrygel", "τοποθετηση ακριτζελ", "τοποθετηση ακρυτζελ",
        "τοποθετηση gel", "τοποθετηση τζελ", "συντηρηση ακρυλικο αθηνα", "συντηρηση ακρυλικο gel αθηνα",
        "συντηρηση ακρυλικο τζελ αθηνα", "συντηρηση acrygel αθηνα", "συντηρηση ακριτζελ αθηνα",
        "συντηρηση ακρυτζελ αθηνα", "συντηρηση gel αθηνα", "συντηρηση τζελ αθηνα", "τοποθετηση ακρυλικο αθηνα",
        "τοποθετηση ακρυλικο gel αθηνα", "τοποθετηση ακρυλικο τζελ αθηνα", "τοποθετηση acrygel αθηνα",
        "τοποθετηση ακριτζελ αθηνα", "τοποθετηση ακρυτζελ αθηνα", "τοποθετηση gel αθηνα", "τοποθετηση τζελ αθηνα",
        "συντηρηση ακρυλικο κοντα μου", "συντηρηση ακρυλικο gel κοντα μου", "συντηρηση ακρυλικο τζελ κοντα μου", "" +
        "συντηρηση acrygel κοντα μου", "συντηρηση ακριτζελ κοντα μου", "συντηρηση ακρυτζελ κοντα μου",
        "συντηρηση gel κοντα μου", "συντηρηση τζελ κοντα μου", "τοποθετηση ακρυλικο κοντα μου",
        "τοποθετηση ακρυλικο gel κοντα μου", "τοποθετηση ακρυλικο τζελ κοντα μου", "τοποθετηση acrygel κοντα μου",
        "τοποθετηση ακριτζελ κοντα μου", "τοποθετηση ακρυτζελ κοντα μου", "τοποθετηση gel κοντα μου",
        "τοποθετηση τζελ κοντα μου", "gel", "τζελ", "acrygel", "ακρυτζελ", "ακριτζελ", "αποτριχωση",
        "αποτριχωση αθηνα", "αποτριχωση με κερι", "αποτριχωση με κερι αθηνα", "αποτριχωση με κλωστη",
        "αποτριχωση με κλωστη αθηνα", "καθαρισμος προσωπου", "βλεφαριδες", "eyelashes", "βλεφαριδες αθηνα",
        "βλεφαριδες κοντα μου", "εντυπωσιακες βλεφαριδες", "τοποθετηση βλεφαριδες", "τοποθετηση βλεφαριδες κοντα μου",
        "φυσικη ενισχυση", "φυσικη ενισχυση νυχιων", "φυσικη ενισχυση νυχια", "τοξωτο φρυδι", "ακρυτζελ τι ειναι",
        "βλεφαριδεσ extension τριχα τριχα", "νιχα", "φρυδια σχηματα", "nails beauty", "τζελ νυχιων",
        "φυσικη θεραπεια νυχιων μετα απο ημιμονιμο", "τοποθετηση extension βλεφαριδων", "νυχια gel", "νυχια τζελ",
        "νυχια acrygel", "νυχια ακρυτζελ", "νυχια ακριτζελ", "νυχια ακρυλικο", "nixia gel", "nixia τζελ",
        "nixia acrygel", "nixia ακρυτζελ", "nixia ακριτζελ", "nixia ακρυλικο", "nuxia gel", "nuxia τζελ",
        "nuxia acrygel", "nuxia ακρυτζελ", "nuxia ακριτζελ", "nuxia ακρυλικο"],
    "gallery": ["ιδεες νυχια", "τι νυχια να κανω", "εντυπωσιακα νυχια", "σχεδια νυχια", "nail art",
        "ακρυλικα νυχια", "acrygel νυχια", "μακρια νυχια", "τεχνητα νυχια", "μυτερα νυχια", "νυχια στιλετο",
        "νυχια αμυγδαλωτα", "βαμμένα νύχια", "βαμμενα νυχια ποδιων", "χτιστα νυχια με τζελ", "ακρυλικο ή τζελ",
        "χτιστα νυχια", "νυχια φυσικο χρωμα", "τι χρωμα να βαψω τα νυχια μου", "ακρυλικο νυχια", "ακρυλικο τζελ",
        "επιμηκυνση νυχιων με τζελ", "νυφικα νυχια ποδιων", "νυφικα νυχια με τζελ", "πολυ κοντα νυχια",
        "νυχια εικονες"],
    "pricing": ["νυχια τιμη", "βλεφαριδες τιμη", "φθηνα νυχια", "ακρυλικο νυχια τιμεσ", "παραφινη πεντικιουρ",
        "παραφινη μανικιουρ", "νυχια προσφορες", "φρυδια τιμη", "φρυδια tattoo τιμη"],
    "contact": ["νυχια αθηνα", "νυχια κολωνος", "νυχια ακαδημια πλατωνος", "αιμονος", "λενορμαν",
        "στουντιο περιποιησησ νυχιων", "στουντιο αισθητικης", "νυχαδικο", "νιχαδικο", "νυχου", "νιχου",
        "pedicure athens", "νύχια κοντά", "νύχια κοντά μου", "νυχια μαγαζια", "nails for you ραντεβου",
        "nails for you περιστερι", "νυχαδικα περιστερι", "nails αθηνα", "μαγαζι με νυχια", "akra nail spa"],
}


const Seo = ({ title, description, pathSlug, keywords }) => {
    const url = `https://dadinabeautyworld.com/${pathSlug}`

    return (
        <HelmetProvider>
            <Helmet htmlAttributes={{ lang: 'el' }} title={title} meta={[
                {
                    name: 'keywords',
                    content: keywordsData[keywords].join(),
                },
            ]}
                    link={[
                        {
                            rel: 'canonical',
                            href: url,
                        },
                    ]}
            />
        </HelmetProvider>
    );
}

export default Seo;