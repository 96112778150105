// import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import ResponsiveAppBar from "./components/nav";
import React from "react";

export default function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/:page" element={<ResponsiveAppBar />} />
                <Route path="*" element={<ResponsiveAppBar />} />
            </Routes>
        </BrowserRouter>
    );
}
