import Typography from "@mui/material/Typography";
import React from 'react';
import Carousel from 'react-material-ui-carousel'
import {Card, Grid} from '@mui/material'
import Image from "mui-image";
import Seo from "../components/seo"
import * as carousel from "../images/home/carousel";
import * as certs from "../images/home/certifications";


const topCarouselItems = [
    {
        Items: [
            {
                Name: "nails 2",
                Image: carousel.Nails_2
            },
            {
                Name: "nails 4",
                Image: carousel.Nails_4
            },
            {
                Name: "nails 10",
                Image: carousel.Nails_10
            },
        ]
    },
    {
        Items: [
            {
                Name: "nails 5",
                Image: carousel.Nails_5
            },
            {
                Name: "nails 11",
                Image: carousel.Nails_11
            },
            {
                Name: "nails 7",
                Image: carousel.Nails_7
            },
        ]
    },
]

const certCarouselItems = [
    {
        Items: [
            {
                Name: "opi",
                Image: certs.OPI
            }]
    },
    {
        Items: [
            {
                Name: "rbs",
                Image: certs.RBS
            }]
    },
    {
        Items: [
            {
                Name: "top_tal",
                Image: certs.TopTalent
            }]
    },
    {
        Items: [
            {
                Name: "trophy",
                Image: certs.Trophy
            }]
    }
]


const Banner = (props) => {
    const mediaLength = props.length ? props.length : props.perRow;

    let items = [];


    for (let i = 0; i < mediaLength; i++) {
        const item = props.item.Items[i];

        const media = (
            <Grid item xs={props.xsSize} key={item.Name}>
                <Image src={item.Image} duration={0} height={props.height} fit={"contain"} />
            </Grid>
        )

        items.push(media);
    }

    return (
        <Card className="Banner" elevation={0}>
            <Grid container spacing={0} className="BannerGrid" margin={"auto"} >
                {items}
            </Grid>
        </Card>
    )
}

export default function Home() {
    return(
        <div>
            <Seo title={"Dadina Beauty World - dadinabeautyworld.com"}
                 pathSlug={""}
                 keywords={"home"}
            />
            <Carousel >
                {
                    topCarouselItems.map((item, index) => {
                        return <Banner item={item} key={index} perRow={3} xsSize={4} height={"auto"} />
                    })
                }
            </Carousel>
            <Grid
                container
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="center"
            >
                <Grid item xs={10}>
                    <Typography variant={"h3"}
                                textAlign={"center"}
                                color={"#B76E79"}
                                fontWeight={"bold"}
                                marginTop={4}
                                marginBottom={2}
                        >
                            Dadina Beauty World
                        </Typography>
                </Grid>
            </Grid>
            <Typography
                marginTop={2}
                marginLeft={3}
                marginRight={3}
                align={"center"}
            >
                Καλώς ήρθατε σε ένα σύγχρονο κέντρο αισθητικής και περιποίησης.
            </Typography>
            <Typography
                marginLeft={3}
                marginRight={3}
                align={"center"}
            >
                Στον χώρο μας θα βρείτε μεγάλη ποικιλία υλικών κορυφαίας
                ποιότητας από επώνυμες εταιρείες του κλάδου, όπως επίσης εργαλεία
                και εξοπλισμό υψηλών προδιαγραφών.
            </Typography>
            <Typography
                marginLeft={3}
                marginRight={3}
                align={"center"}
            >
                Σε όλες μας τις δημιουργείες ακολουθούμε τις τάσεις της μόδας χρησιμοποιώντας
                νέες τεχνικές, κρατώντας υπόψιν τις προσωπικές σας προτιμήσεις.
            </Typography>
            <Typography
                marginBottom={2}
                marginLeft={3}
                marginRight={3}
                align={"center"}
            >
                Με δεκαετή πείρα, και τηρώντας πάντα τους κανόνες υγιεινής,
                στόχος μας είναι η καλύτερη εξυπηρέτιση και φροντίδα των πελατών μας.
            </Typography>
            <Typography variant={"h6"}
                        textAlign={"center"}
                        color={"#B76E79"}
                        fontWeight={"bold"}
                        marginBottom={2}
            >
                Διακρίσεις Ονυχοπλαστικής & Nail Art
            </Typography>
            <ul>
                <li>
                    <Typography variant={"body1"}>
                        3<sup>η</sup> θέση Παγκόσμιο 2019
                    </Typography>
                </li>
                <li>
                    <Typography variant={"body1"}>
                        1<sup>η</sup> θέση Πανευρωπαϊκό 2018 - Κύπελο
                    </Typography>
                </li>
                <li>
                    <Typography variant={"body1"} marginBottom={3}>
                        4<sup>η</sup> θέση Πανελλήνιο 2017
                    </Typography>
                </li>
            </ul>
            <Typography variant={"h1"}
                        fontSize={"1.25rem"}
                        textAlign={"center"}
                        color={"#B76E79"}
                        fontWeight={"bold"}
                        marginBottom={2}>
                Πιστοποιήσεις
            </Typography>
            <ul>
                <li>
                    <Typography variant={"body1"}>
                        Eyebrow Tattoo
                    </Typography>
                </li>
                <li>
                    <Typography variant={"body1"} marginBottom={3}>
                        Nail Art Trend
                    </Typography>
                </li>
            </ul>
            <Typography variant={"h6"}
                        textAlign={"center"}
                        color={"#B76E79"}
                        fontWeight={"bold"}
                        marginBottom={2}>
                Σεμινάρια
            </Typography>
            <ul>
                <li>
                    <Typography variant={"body1"}>
                        Αερογράφος
                    </Typography>
                </li>
                <li>
                    <Typography variant={"body1"}>
                        Βλεφαρίδες
                    </Typography>
                </li>
                <li>
                    <Typography variant={"body1"}>
                        Μανικιούρ-Πεντικιούρ
                    </Typography>
                </li>
                <li>
                    <Typography variant={"body1"}>
                        Ποδολογία
                    </Typography>
                </li>
                <li>
                    <Typography variant={"body1"}>
                        Ονυχοπλαστική
                    </Typography>
                </li>
                <li>
                    <Typography variant={"body1"}>
                        Περιποίηση Προσώπου
                    </Typography>
                </li>
                <li>
                    <Typography variant={"body1"} marginBottom={3}>
                        Αποτρίχωση με Κερί και Κλωστή
                    </Typography>
                </li>
            </ul>
            <Carousel>
                {
                    certCarouselItems.map((item, index) => {
                        return <Banner item={item} key={index} perRow={1} xsSize={12} height={300} />
                    })
                }
            </Carousel>
            <Typography marginBottom={3}/>
        </div>
    )
}