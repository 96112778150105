import {Box, Grid, ImageList, ImageListItem, Tab, Tabs, Typography} from "@mui/material";
import {useState} from "react";
import Image from "mui-image";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import {Design_1, Design_2, Design_3, Design_4, Design_5, Design_6, Tattoo_1} from "../images/gallery/creations";
// import {Shop_1, Shop_2, Shop_3, Shop_4, Shop_5} from "../images/gallery/space";
import * as creations from "../images/gallery/creations";
import * as space from "../images/gallery/space";
import Seo from "../components/seo";
import * as React from "react";
// import design_1 from "../images/gallery/creations/design_1.jpg"
// import design_2 from "../images/gallery/creations/design_2.jpg"
// import design_3 from "../images/gallery/creations/design_3.jpg"
// import design_4 from "../images/gallery/creations/design_4.jpg"
// import design_5 from "../images/gallery/creations/design_5.jpg"
// import design_6 from "../images/gallery/creations/design_6.jpg"
// import tattoo_1 from "../images/gallery/creations/tattoo_1.jpg"
// import shop_1 from "../images/gallery/space/shop_1.jpg"
// import shop_2 from "../images/gallery/space/shop_2.jpg"
// import shop_3 from "../images/gallery/space/shop_3.jpg"
// import shop_4 from "../images/gallery/space/shop_4.jpg"
// import shop_5 from "../images/gallery/space/shop_5.jpg"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 2,
                        top: 2,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function ImagePopup(props) {
    const [open, setOpen] = useState(props.open);

    const handleClose = () => {
        setOpen(false);
        props.clearImage(null)
    };
    return(
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth={false}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} />
                <DialogContent >
                    <Image src={props.image} duration={0} fit={"contain"} />
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

export default function Gallery() {
    const [value, setValue] = useState("creations");
    const [popupImage, setPopupImage] = useState(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClickImage = (event) => {
        setPopupImage(event.currentTarget.getAttribute('src'))
    }

    return(
        <div>
            <Seo title={"Gallery - dadinabeautyworld.com"}
                 pathSlug={"gallery"}
                 keywords={"gallery"}
            />
            <Typography variant={"h1"}
                        textAlign={"center"}
                        color={"#B76E79"}
                        fontWeight={"bold"}
                        marginTop={2}
                        marginBottom={2}>
                Gallery
            </Typography>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Tabs value={value} onChange={handleChange} centered>
                    <Tab label={<Typography color={"#B76E79"}
                                            textTransform={"none"}>
                                    Δημιουργείες I
                                </Typography>}
                         value={"creations"}
                    />
                    <Tab label={<Typography color={"#B76E79"}
                                            textTransform={"none"}>
                                    Δημιουργείες II
                                </Typography>}
                         value={"creations2"}
                    />
                    <Tab label={<Typography color={"#B76E79"}
                                            textTransform={"none"}>
                                    Ο χώρος μας
                                </Typography>}
                         value={"space"}
                    />
                </Tabs>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={10}>
                        {value === "creations" &&
                            <ImageList cols={3}>
                                {creationsData.map((item) => (
                                    <ImageListItem key={item.img}>
                                        <Image
                                            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                            duration={0}
                                            onClick={handleClickImage}
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>

                        }
                        {value === "creations2" &&
                            <ImageList cols={3}>
                                {creations2Data.map((item) => (
                                    <ImageListItem key={item.img}>
                                        <Image
                                            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                            duration={0}
                                            onClick={handleClickImage}
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>

                        }
                        {value === "space" &&
                            <ImageList cols={3}>
                                {spaceData.map((item) => (
                                    <ImageListItem key={item.img}>
                                        <Image
                                            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                            duration={0}
                                            onClick={handleClickImage}
                                            height={"100%"}
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        }
                    </Grid>
                </Grid>
                {popupImage && <ImagePopup image={popupImage} open={true} clearImage={setPopupImage} />}
            </Box>
        </div>
    )
}

const creations2Data = [
    {
        img: creations.Nails_28
    },
    {
        img: creations.Nails_2
    },
    {
        img: creations.Nails_3
    },
    {
        img: creations.Nails_4
    },
    {
        img: creations.Nails_5
    },
    {
        img: creations.Nails_6
    },
    {
        img: creations.Nails_7
    },
    {
        img: creations.Nails_8
    },
    {
        img: creations.Nails_9
    },
    {
        img: creations.Nails_10
    },
    {
        img: creations.Nails_11
    },
    {
        img: creations.Nails_12
    },
    {
        img: creations.Nails_13
    },
    {
        img: creations.Nails_14
    },
    {
        img: creations.Nails_15
    },
    {
        img: creations.Nails_16
    },
    {
        img: creations.Nails_17
    },
    {
        img: creations.Nails_18
    },
    {
        img: creations.Nails_19
    },
    {
        img: creations.Nails_20
    },
    {
        img: creations.Nails_21
    },
    {
        img: creations.Nails_27
    },
];

const creationsData = [
    {
        img: creations.Nails_1
    },
    {
        img: creations.Nails_22
    },
    {
        img: creations.Nails_23
    },
    {
        img: creations.Nails_24
    },
    {
        img: creations.Nails_25
    },
    {
        img: creations.Nails_26
    },
    {
        img: creations.Nails_29
    },
    {
        img: creations.Nails_30
    },
    {
        img: creations.Nails_31
    },
    {
        img: creations.Nails_32
    },
    {
        img: creations.Nails_33
    },
    {
        img: creations.Nails_34
    }
]

const spaceData = [
    {
        img: space.Shop_1
    },
    {
        img: space.Shop_2
    },
    {
        img: space.Shop_3
    },
    {
        img: space.Shop_4
    },
    {
        img: space.Shop_5
    },
    {
        img: space.Shop_6
    }
];