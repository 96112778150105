import {
  Grid,
  Typography,
  Link,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  Place,
  Phone,
  Smartphone,
  Instagram,
  Facebook,
  AccessTimeFilled,
} from "@mui/icons-material";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import * as React from "react";
import Seo from "../components/seo";

export default function Contact() {
  return (
    <div>
      <Seo
        title={"Επικοινωνία - dadinabeautyworld.com"}
        pathSlug={"contact"}
        keywords={"contact"}
      />
      <Typography
        variant={"h1"}
        textAlign={"center"}
        color={"#B76E79"}
        fontWeight={"bold"}
        marginTop={2}
        marginBottom={2}
      >
        Επικοινωνία
      </Typography>
      <Grid container direction={"column"} alignItems={"center"} spacing={2}>
        <Grid container item justifyContent={"center"}>
          <MapContainer
            center={[37.99299093353088, 23.71324019984375]}
            zoom={16}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attribution/">CARTO</a>'
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            />
            <Marker position={[37.99299093353088, 23.71324019984375]}></Marker>
          </MapContainer>
        </Grid>
        <Grid
          container
          item
          spacing={2}
          marginLeft={"auto"}
          marginRight={"auto"}
          width={"300px"}
        >
          <Grid item>
            <Place sx={{ color: "#B76E79" }} />
          </Grid>
          <Grid item>
            <Grid item fontWeight={"bold"}>
              Διεύθυνση
            </Grid>
            <Grid item>
              <Typography>
                <Link
                  href={"https://goo.gl/maps/bd7fqynLY5xEykEs9"}
                  underline="hover"
                >
                  Αίμωνος 8, Αθήνα
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          spacing={2}
          marginLeft={"auto"}
          marginRight={"auto"}
          width={"300px"}
        >
          <Grid item>
            <Phone sx={{ color: "#B76E79" }} />
          </Grid>
          <Grid item>
            <Grid item fontWeight={"bold"}>
              Σταθερό
            </Grid>
            <Grid item>
              <Grid item>
                <Typography>
                  <Link href={"tel:+302105124280"} underline="hover">
                    +30 2105124280
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          spacing={2}
          marginLeft={"auto"}
          marginRight={"auto"}
          width={"300px"}
        >
          <Grid item>
            <Smartphone sx={{ color: "#B76E79" }} />
          </Grid>
          <Grid item>
            <Grid item fontWeight={"bold"}>
              Κινητό
            </Grid>
            <Grid item>
              <Grid item>
                <Typography>
                  <Link href={"tel:+306909154026"} underline="hover">
                    +30 6909154026
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          spacing={2}
          marginLeft={"auto"}
          marginRight={"auto"}
          width={"300px"}
        >
          <Grid item>
            <Instagram sx={{ color: "#B76E79" }} />
          </Grid>
          <Grid item>
            <Grid item fontWeight={"bold"}>
              Instagram
            </Grid>
            <Grid item>
              <Typography>
                <Link
                  href={"https://www.instagram.com/dadina_beauty_world/"}
                  underline="hover"
                >
                  dadina_beauty_world
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          spacing={2}
          marginLeft={"auto"}
          marginRight={"auto"}
          width={"300px"}
        >
          <Grid item>
            <Facebook sx={{ color: "#B76E79" }} />
          </Grid>
          <Grid item>
            <Grid item fontWeight={"bold"}>
              Facebook
            </Grid>
            <Grid item>
              <Typography>
                <Link
                  href={
                    "https://www.facebook.com/people/Dadina-beauty-world/100086787998550/"
                  }
                  underline="hover"
                >
                  Dadina beauty world
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          spacing={2}
          marginLeft={"auto"}
          marginRight={"auto"}
          width={"300px"}
          marginBottom={4}
        >
          <Grid item>
            <AccessTimeFilled sx={{ color: "#B76E79" }} />
          </Grid>
          <Grid item>
            <Grid item fontWeight={"bold"}>
              Ωράριο
            </Grid>
          </Grid>
          <TableContainer component={Paper} sx={{ boxShadow: 0, border: 0 }}>
            <Table aria-label="table">
              <TableBody>
                <TableRow>
                  <TableCell sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                    <Typography>Δευτέρα</Typography>
                  </TableCell>
                  <TableCell sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                    <Typography>Κλειστά</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                    <Typography>Τρίτη</Typography>
                  </TableCell>
                  <TableCell sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                    <Typography>9:00 - 21:00</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                    <Typography>Τετάρτη</Typography>
                  </TableCell>
                  <TableCell sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                    <Typography>9:00 - 17:00</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                    <Typography>Πέμπτη</Typography>
                  </TableCell>
                  <TableCell sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                    <Typography>9:00 - 21:00</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                    <Typography>Παρασκευή</Typography>
                  </TableCell>
                  <TableCell sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                    <Typography>9:00 - 21:00</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                    <Typography>Σάββατο</Typography>
                  </TableCell>
                  <TableCell sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                    <Typography>9:00 - 15:00</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                    <Typography>Κυριακή</Typography>
                  </TableCell>
                  <TableCell sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
                    <Typography>Κλειστά</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}
