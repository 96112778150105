import * as React from 'react';
import {Grid, AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Button, MenuItem} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {useNavigate, useParams} from "react-router-dom";
import Image from 'mui-image';
import Home from "../pages/home";
import Gallery from "../pages/gallery";
import Pricing from "../pages/pricing";
import Contact from "../pages/contact";
import navLogoRound from "../images/navLogoRound.webp";
import {useEffect} from "react";

function ResponsiveAppBar() {
    const pages = ['Αρχική', 'Gallery', 'Τιμοκατάλογος', 'Επικοινωνία'];
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const navigate = useNavigate();
    const { page } = useParams();
    const [selectedPage, setSelectedPage] = React.useState("");

    useEffect(() => {
        const pages_to_nav = {"Αρχική": "", "Gallery": "gallery", "Τιμοκατάλογος": "pricing", "Επικοινωνία": "contact"};
        if (Object.values(pages_to_nav).includes(page)) {
            setSelectedPage(page)
            navigate(`/${page}`)
        }
        else {
            setSelectedPage("")
            navigate(`/`)
        }
    }, [navigate, page])

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleClickNavMenuOption = (event) => {
        const pages_to_nav = {"Αρχική": "", "Gallery": "gallery", "Τιμοκατάλογος": "pricing", "Επικοινωνία": "contact"};
        setAnchorElNav(null);
        let nav_to = pages_to_nav[event.currentTarget.textContent]
        if(nav_to !== undefined) {
            setSelectedPage(nav_to)
            navigate(`/${nav_to}`);
        }
        else {
            setSelectedPage("")
            navigate(`/`);
        }
    };

    let pages_to_nav = {"Αρχική": "", "Gallery": "gallery", "Τιμοκατάλογος": "pricing", "Επικοινωνία": "contact"};
    return (
        <div>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Grid item maxWidth="5%"
                              sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
                        >
                            <Image
                                src={navLogoRound}
                                duration={0}
                            />
                        </Grid>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleClickNavMenuOption}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={handleClickNavMenuOption}>
                                        <Typography textAlign="center" sx={{ color: '#B76E79' }}>{page}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Grid item maxWidth="10%" sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
                            <Image
                                src={navLogoRound}
                                duration={0}
                            />
                        </Grid>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page}
                                    onClick={handleClickNavMenuOption}
                                    sx={{ my: 2, color: '#B76E79', display: 'block', textTransform: 'none' }}
                                    to={pages_to_nav[page]+"/"}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Grid container justifyContent={"center"}>
                <Grid item xs={12} sm={6} lg={4}>
            { selectedPage === "" && <Home />}
            { selectedPage === "gallery" && <Gallery />}
            { selectedPage === "pricing" && <Pricing />}
            { selectedPage === "contact" && <Contact />}
                </Grid>
            </Grid>
        </div>
    );
}
export default ResponsiveAppBar;
