import {createTheme} from "@mui/material";
import GreatVibesWoff2 from './fonts/GreatVibes.woff2';

const theme = createTheme({
  palette: {
    primary: {
      main: '#160016',
    },
    secondary: {
      main: '#101820',
    },
    background: {
      default: '#FFF9FF',
      paper: '#FFF9FF',
    },
    text: {
      primary: '#160016',
      secondary: '#FFF9FF',
    },
  },
  typography: {
    h1: {
      fontSize: "1.5rem"
    },
    h3: {
      fontFamily: ['GreatVibes'].join(','),
      fontSize: "3rem"
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': {
          fontFamily: 'GreatVibes',
          fontStyle: 'regular',
          fontDisplay: 'swap',
          fontWeight: 400,
          src: `local('GreatVibes'), local('GreatVibes-Bold'), url(${GreatVibesWoff2}) format('woff2')`,
        },
      },
    },
  }
});

export default theme;
