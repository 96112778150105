import Typography from "@mui/material/Typography";
import * as React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid} from '@mui/material';
import Seo from "../components/seo";

function createData(name, price) {
    return { name, price };
}

const maniRows = [
    createData('Μανικούρ απλό', 10),
    createData('Μανικούρ ημιμόνιμο', 13),
    createData('Μανικούρ με ενισχυμένη βάση', 15),
    createData('Αφαίρεση ημιμόνιμο', 5),
    createData('Τοποθέτηση Gel', 30),
    createData('Συντήρηση Gel', 25),
    createData('Τοποθέτηση Ακρυλικού', 35),
    createData('Συντήρηση Ακρυλικού', 30),
    createData('Τοποθέτηση Acrygel', 40),
    createData('Συντήρηση Acrygel', 35),
    createData('Αφαίρεση Acrygel', 10),
    createData('Nail Art / Strass', "0.50€-5"),
];

const pediRows = [
    createData('Πεντικιούρ απλό', 15),
    createData('Πεντικιούρ ημιμόνιμο', 20),
    createData('Πεντικιούρ θεραπευτικό', 25),
    createData('Έλασμα ονυχοκρύπτωσης', 40)
];

const lashRows = [
    createData('Βλεφαρίδες διαρκείας τρίχα-τρίχα / 3D', 50),
    createData('Συντήρηση βλεφαρίδων', 40),
    createData('Αφαίρεση βλεφαρίδων', 10)
];

const eyebrowsRows = [
    createData('Tattoo φρυδιών', 150),
    createData('Επαναληπτικό tattoo φρυδιών', 50)
];

const wellnessRows = [
    createData('Καθαρισμός προσώπου', 50),
    createData('Ενυδάτωση προσώπου', 30),
    createData('Θεραπεία παραφίνης χέρια', 5),
    createData('Θεραπεία παραφίνης  πόδια', 10),
];

const waxRows = [
    createData('Σχήμα φρυδιών', 5),
    createData('Άνω χείλος', 5),
    createData('Χέρια', 15),
    createData('Πόδια (ολόκληρα)', 25),
    createData('Πλάτη ανδρική', "25€-35"),
    createData('Στήθος ανδρικό', "20€-25"),
];

export default function Pricing() {
    return(
        <div>
            <Seo title={"Τιμοκατάλογος - dadinabeautyworld.com"}
                 pathSlug={"pricing"}
                 keywords={"pricing"}
            />
            <Typography variant={"h1"}
                        textAlign={"center"}
                        color={"#B76E79"}
                        fontWeight={"bold"}
                        marginTop={2}
                        marginBottom={2}>
                Τιμοκατάλογος
            </Typography>
            <Grid container justifyContent={"center"} spacing={2}>
                <Grid item xs={10}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography color={"#B76E79"}
                                                    fontWeight={"bold"}>
                                            Μανικιούρ
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography color={"#B76E79"}
                                                    fontWeight={"bold"}>
                                            Τιμή
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {maniRows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" sx={{paddingTop: 0, paddingBottom: 0}}>
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right" sx={{paddingTop: 0, paddingBottom: 0}}>{row.price}€</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={10}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography color={"#B76E79"}
                                                    fontWeight={"bold"}>
                                            Πεντικιούρ
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography color={"#B76E79"}
                                                    fontWeight={"bold"}>
                                            Τιμή
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pediRows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" sx={{paddingTop: 0, paddingBottom: 0}}>
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right" sx={{paddingTop: 0, paddingBottom: 0}}>{row.price}€</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={10}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography color={"#B76E79"}
                                                    fontWeight={"bold"}>
                                            Βλεφαρίδες
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography color={"#B76E79"}
                                                    fontWeight={"bold"}>
                                            Τιμή
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {lashRows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" sx={{paddingTop: 0, paddingBottom: 0, width:"60%"}}>
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right" sx={{paddingTop: 0, paddingBottom: 0}}>{row.price}€</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={10}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography color={"#B76E79"}
                                                    fontWeight={"bold"}>
                                            Φρύδια
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography color={"#B76E79"}
                                                    fontWeight={"bold"}>
                                            Τιμή
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {eyebrowsRows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" sx={{paddingTop: 0, paddingBottom: 0}}>
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right" sx={{paddingTop: 0, paddingBottom: 0}}>{row.price}€</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={10}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography color={"#B76E79"}
                                                    fontWeight={"bold"}>
                                            Περιποίηση
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography color={"#B76E79"}
                                                    fontWeight={"bold"}>
                                            Τιμή
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {wellnessRows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" sx={{paddingTop: 0, paddingBottom: 0}}>
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right" sx={{paddingTop: 0, paddingBottom: 0}}>{row.price}€</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={10}
                      marginBottom={4}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography color={"#B76E79"}
                                                    fontWeight={"bold"}>
                                            Αποτρίχωση
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography color={"#B76E79"}
                                                    fontWeight={"bold"}>
                                            Τιμή
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {waxRows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" sx={{paddingTop: 0, paddingBottom: 0}}>
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right" sx={{paddingTop: 0, paddingBottom: 0}}>{row.price}€</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    )
}